// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes mic-indicator_popIn_Unn1e {\n    from {transform: scale(0.5)}\n    to {transform: scale(1)}\n}\n\n.mic-indicator_mic-img_sD1iv {\n    margin: 10px;\n    transform-origin: center;\n    animation: mic-indicator_popIn_Unn1e 0.1s ease-in-out;\n}\n", "",{"version":3,"sources":["webpack://./src/components/mic-indicator/mic-indicator.css"],"names":[],"mappings":"AAAA;IACI,MAAM,qBAAqB;IAC3B,IAAI,mBAAmB;AAC3B;;AAEA;IACI,YAAY;IACZ,wBAAwB;IACxB,qDAAiC;AACrC","sourcesContent":["@keyframes popIn {\n    from {transform: scale(0.5)}\n    to {transform: scale(1)}\n}\n\n.mic-img {\n    margin: 10px;\n    transform-origin: center;\n    animation: popIn 0.1s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mic-img": "mic-indicator_mic-img_sD1iv",
	"micImg": "mic-indicator_mic-img_sD1iv",
	"popIn": "mic-indicator_popIn_Unn1e"
};
module.exports = ___CSS_LOADER_EXPORT___;
