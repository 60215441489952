// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".monitor-list_monitor-list_TBYTy {\n    /* Width/height are set by the component, margin: auto centers in fullscreen */\n    margin: auto;\n    pointer-events: none;\n    overflow: hidden;\n}\n\n.monitor-list_monitor-list-scaler_H3MuI {\n    /* Scaling for monitors should happen from the top left */\n    transform-origin: left top;\n}\n\n::-ms-clear { display: none; }\n", "",{"version":3,"sources":["webpack://./src/components/monitor-list/monitor-list.css"],"names":[],"mappings":"AAAA;IACI,8EAA8E;IAC9E,YAAY;IACZ,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,yDAAyD;IACzD,0BAA0B;AAC9B;;AAEA,cAAc,aAAa,EAAE","sourcesContent":[".monitor-list {\n    /* Width/height are set by the component, margin: auto centers in fullscreen */\n    margin: auto;\n    pointer-events: none;\n    overflow: hidden;\n}\n\n.monitor-list-scaler {\n    /* Scaling for monitors should happen from the top left */\n    transform-origin: left top;\n}\n\n::-ms-clear { display: none; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"monitor-list": "monitor-list_monitor-list_TBYTy",
	"monitorList": "monitor-list_monitor-list_TBYTy",
	"monitor-list-scaler": "monitor-list_monitor-list-scaler_H3MuI",
	"monitorListScaler": "monitor-list_monitor-list-scaler_H3MuI"
};
module.exports = ___CSS_LOADER_EXPORT___;
