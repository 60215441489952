// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alerts_alerts-inner-container_0KOoO {\n    min-width: 200px;\n    max-width: 548px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/alerts/alerts.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".alerts-inner-container {\n    min-width: 200px;\n    max-width: 548px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alerts-inner-container": "alerts_alerts-inner-container_0KOoO",
	"alertsInnerContainer": "alerts_alerts-inner-container_0KOoO"
};
module.exports = ___CSS_LOADER_EXPORT___;
