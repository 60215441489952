// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controls_controls-container_FKkXX {\n    display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/components/controls/controls.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB","sourcesContent":[".controls-container {\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls-container": "controls_controls-container_FKkXX",
	"controlsContainer": "controls_controls-container_FKkXX"
};
module.exports = ___CSS_LOADER_EXPORT___;
