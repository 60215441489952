// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody,\n.index_app_ZUgsL {\n    /* probably unecessary, transitional until layout is refactored */\n    width: 100%; \n    height: 100%;\n    margin: 0;\n\n    /* Setting min height/width makes the UI scroll below those sizes */\n    min-width: 1024px;\n    min-height: 640px; /* Min height to fit sprite/backdrop button */\n}\n\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n\n* { box-sizing: border-box; }\n", "",{"version":3,"sources":["webpack://./src/playground/index.css"],"names":[],"mappings":"AAAA;;;IAGI,iEAAiE;IACjE,WAAW;IACX,YAAY;IACZ,SAAS;;IAET,mEAAmE;IACnE,iBAAiB;IACjB,iBAAiB,EAAE,6CAA6C;AACpE;;AAEA,mEAAmE;;AACnE,IAAI,sBAAsB,EAAE","sourcesContent":["html,\nbody,\n.app {\n    /* probably unecessary, transitional until layout is refactored */\n    width: 100%; \n    height: 100%;\n    margin: 0;\n\n    /* Setting min height/width makes the UI scroll below those sizes */\n    min-width: 1024px;\n    min-height: 640px; /* Min height to fit sprite/backdrop button */\n}\n\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n* { box-sizing: border-box; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "index_app_ZUgsL"
};
module.exports = ___CSS_LOADER_EXPORT___;
